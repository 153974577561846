import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

import Typewriter from 'typewriter-effect'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <a href="/" className="image avatar">
            <img src={avatar} alt="" />
          </a>
          <h1>
            <Typewriter
              onInit={typewriter => {
                typewriter
                  .typeString('Hi there!')
                  .pauseFor(1000)
                  .typeString("<br /> <strong> I'm Angela Lin</strong>" )
                  .pauseFor(1000)
                  .typeString("<br /> and I'm a software developer!")
                  .start()
              }}
            />
          </h1>
          <ul className="icons">
                        <li><a href="https://github.com/linyh0207" className="icon fa-github"><span className="label">Github</span></a></li>
                        <li><a href="https://www.linkedin.com/in/angelalindev/" className="icon fa-linkedin"><span className="label">Dribbble</span></a></li>
                    </ul>
        </div>

        <Footer />
      </header>
    )
  }
}

export default Header
